* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "poppins", Times, serif;
}

::-webkit-scrollbar {
  display: none;
}

.container {
  padding: 30px 30px;
  width: 100%;
  margin: 20px auto;
  max-width: 900px;
}

.image-stack {
  display: grid;
  position: relative;
  grid-template-columns: repeat(12, 1fr);
}

.image-stack__item--bottom {
  grid-column: 4 / -1;
  grid-row: 1;
}

.image-stack__item--top {
  grid-row: 1;
  grid-column: 1 / span 8;
  padding-top: 50%;
  z-index: 1;
}

.image {
  width: 100%;
  height: 350px;
  display: block;
}

/* @media (min-width: 320px) {
  .image {
    height: 200px;
  }
}
@media (min-width: 375px) {
}
@media (min-width: 425px) {
  .image {
    height: 280px;
  }
}
@media (max-width: 500) {
  .image {
    width: 100%;
    height: 350px;
    display: block;
  }
} */
/* @media only screen {
} */

@media screen and (min-width: 320px) and (max-width: 767px) {
  .image {
    height: 200px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .image {
    width: 450px;
    height: 300px;
    margin: 100px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .image {
    width: 525px;
    height: 300px;
    margin: 100px;
  }
}
@media screen and (min-width: 1040px) and (max-width: 1717px) {
  .image {
    width: 400px;
    height: 300px;
    margin: 100px;
  }
}
