.about1 {
  /* margin: 15%;
    margin-top: 50px;
    margin-bottom: 50px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 50px 8% auto 15%;
  padding: 0px 10px;
}

.aboutBtn {
  text-align: center;
  background-color: #c70039;
  width: 150px;
  font-weight: 700;
  /* margin: auto; */
  border-radius: 20px;
  padding: 5px 10px;
  color: #fff5e0;
}

.aboutUsHeading {
  margin-top: 30px;
  color: #000;
}
.aboutUsPara {
  margin-top: 30px;
  color: #000;
  line-height: 1.5;
  font-weight: 500;
}
@media (max-width: 767px) {
  .about1 {
    margin: 8%;
  }
}
@media (max-width: 300px) {
  .aboutUsPara {
    line-height: 1.3;
  }
}

/* coppied */
.sliderBtn {
  color: #fff5e0;
  border-radius: 15px;
  background-color: #000;
  padding: 4px 6px 4px 6px;
  font-weight: 600;
}
