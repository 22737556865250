.copyright {
    background-color: #000000;
    color: #ffffff;
    padding: 10px 0;
    text-align: center;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  p {
    margin: 0;
  }