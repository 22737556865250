.whyusPage {
  padding: 5%;
  background-color: #000000;
  color: white;
  /* display: flex;
    flex-direction: column;
    justify-content: flex-end; */
}

.whyus {
  /* margin: 15%;
    margin-top: 50px;
    margin-bottom: 50px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 50px 8% auto 15%;
  padding: 0px 10px;
}

.whyusBtn {
  background-color: #fff5e0;
  width: 170px;
  font-weight: 700;
  border-radius: 20px;
  padding: 5px 10px;
  color: #000;
}

.whyusHeading {
  margin-top: 30px;
  color: rgb(255, 255, 255);
}
.whyusPara {
  margin-top: 30px;
  color: rgb(255, 255, 255);
  line-height: 1.5;
  font-weight: 500;
}

.video {
  border-radius: 5px;
  box-shadow: 0 0 30px rgba(4, 9, 101, 0.81);
}

@media (max-width: 767px) {
  .whyus {
    margin: 8%;
  }
}
@media (max-width: 300px) {
  .aboutUsPara {
    line-height: 1.3;
  }
}
/* @media screen and (min-width: 320px) and (max-width: 424px)  {
    .videostyle{
        width: 100%;
        height: 500px;
    }
    
  }
  @media screen and (min-width: 425px) and (max-width: 767px)  {
    .videostyle{
        width: 350px;
        height: 500px;
    }
    
  } */
/* @media screen and (min-width:768px) and (max-width: 1023px)  {
    .videostyle{
        width: 100%;
        height: 500px;
    }
    
  } */
