.visitUsGrid {
  padding-left: 144px;
  padding-right: 144px;
}
.visitusinnerDiv {
  padding: 30px;
}
.contact1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contactBtn {
  text-align: center;
  background-color: #fff5e0;
  width: 150px;

  font-weight: 700;
  margin: auto;
  border-radius: 20px;
  padding: 5px 10px;
  color: #000;
}

.contactBtnNo {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 98px;
  justify-content: center;
  background-color: #fff5e0;
  /* width: 250px; */
  width: 100%;
  font-weight: 500;
  margin: auto;
  /* font-size: 20px; */
  /* border-radius: 20px; */
  padding: 10px 20px;
  color: #000;
  border-bottom: 5px solid #c70039;
}
/* .mainPaper{
    transition: transform 0.3s ease;
} */
.mapPaper {
  padding: "20px  ";
  width: "100%";
  height: "250px";
}
.addressGrid {
  background-color: #c70039;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #fff5e0;
  padding: 10px;
}
.paper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(
    4,
    9,
    101
  ) !important; /* Background color for the Paper */
  color: white !important;
  margin-bottom: 2%;
  padding: 1rem; /* Padding around the Paper */
}
/* .mainPaper:hover {
    transform: scale(1.1); /* Make the Paper slightly larger on hover 
  } */
.mapTitle {
  text-align: center;
}
.MuiPaper-root {
  padding-right: 0 !important;
}
.visitusHeader {
  font-size: 30px;
  font-weight: 700;
  padding-bottom: 10px;
  color: rgb(0, 1, 7);
}
.getintouchTitle {
  margin: auto;
  margin-top: 30px;
  margin-bottom: 50px;
  font-weight: bolder;
  color: #c70039;
}
.form-control {
  display: block;
  width: 100%;
  padding: 1.2rem 1.2rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.aboutUsPara {
  margin-top: 30px;
  color: rgb(4, 9, 101);
  line-height: 1.5;
  font-weight: 500;
}
@media (max-width: 767px) {
  .about1 {
    margin: 8%;
  }
}
@media screen and (min-width: 320px) and (max-width: 1023px) {
  .aboutUsPara {
    line-height: 1.3;
  }
  .visitUsGrid {
    padding-left: 50px;
    padding-right: 50px;
  }
  .mapPaper {
    padding: "20px  ";
    width: "100%";
    height: "500px";
  }
}

/* @media (max-width: 600px) {
  .gridDiv {
    grid-template-columns: "1fr";
  }
} */
.gridDiv {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 75%;
  margin: 0 auto 30px auto;
  gap: 10px;
}

@media (max-width: 768px) {
  .gridDiv {
    grid-template-columns: 1fr;
  }
}
