.parent {
  position: "relative";
  transition: width 2s;
  /* margin-left: 15%; */
}
.sliderBtn {
  text-align: center;
  color: #000;
  border-radius: 15px;
  background-color: #fff5e0;
  padding: 4px 6px 4px 6px;
  font-weight: 600;
  position: absolute;
  /* margin-left: 160px; */
  top: 100px;
}

.sliderHeading {
  position: absolute;
  top: 150px;
  color: #000;
  font-weight: 700;
  transition-timing-function: ease-in-out;
}
.sliderHeading2 {
  position: absolute;
  top: 180px;
  color: #c70039;
  font-weight: 600;
  font-size: 40px;
  transition-timing-function: ease-in-out;
  padding: 7px;
}

.sliderContent {
  top: 380px;
  font-weight: 900;
  position: absolute;
  color: #fff5e0;
}

.sliderHeading {
  font-size: 20px;
}

.sliderContent {
  font-size: 13px;
  font-weight: 500;
  width: 500px;
}

/* Styles for screens up to 767px */
/* @media (max-width: 767px) {
  .parent {
    margin-left: 5%;
  }

  .sliderHeading2 {
    font-size: 30px;
    top: 240px;
  }

  .sliderContent {
    top: 320px;
    width: 375px;
  }
} */

/* Styles for screens up to 389px */
/* @media (max-width: 389px) {
  .sliderHeading2 {
    font-size: 35px;
    line-height: 0.9;
  }
  .sliderContent {
    top: 310px;
  }
} */

/* Styles for screens 766px and larger */
/* @media (min-width: 389px) and (max-width: 401px) {
  .sliderHeading2 {
    font-size: 35px;
    line-height: 0.9;
  }
} */

/* @media (min-width: 500px) {
  .sliderHeading2 {
    width: 500px;
  }
}

@media (max-width: 400px) {
  .sliderHeading2 {
    font-size: 35px;
    line-height: 0.9;
  }
  .sliderContent {
    top: 350px;
    width: 90%;
  }
} */

/* @media (max-width: 320px) {
  .sliderHeading2 {
    font-size: 10px;
  }
  .sliderContent {
    top: 290px;
    width: 90%;
  }
} */

/* @media (max-width: 426px) {
  .sliderHeading2 {
    font-size: 20px;
    line-height: 0.9;
    padding: 10px;
  }
  .sliderContent {
    top: 290px;
    width: 90%;
  }
} */
/* @media (min-width: 320px) and(max-width:767px) {
  .sliderHeading2 {
    font-size: 10px;
  }
  .sliderContent {
    top: 290px;
    width: 90%;
  }
} */

@media screen and (min-width: 320px) and (max-width: 767px) {
  .sliderHeading2 {
    font-size: 18px;
    padding:20px;
    /* margin-left: 10px; */
  }
  .sliderContent {
    padding:20px;
    top: 240px;
    width: 100%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .sliderHeading2 {
    font-size: 30px;
    margin-left: 10px;
    width: 400px;
  }
  .sliderContent {
    top: 280px;
    width: 370px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .sliderHeading2 {
    font-size: 30px;
    margin-left: 10px;
    width: 600px;
  }
  .sliderContent {
    top: 280px;
    width: 570px;
  }
}

@media (min-width: 1440px) {
  .sliderHeading2 {
    font-size: 30px;
    margin-left: 10px;
    width: 600px;
  }
  .sliderContent {
    top: 280px;
    width: 570px;
  }
}
