.productPage {
  padding: 50px;
}
.proudct1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: auto;
  padding: 0px;
}
.productBtn {
  text-align: center;
  background-color: #fff5e0;
  width: 150px;
  font-weight: 700;
  margin: auto;
  border-radius: 20px;
  padding: 5px 10px;
  color: #000;
}
.productHeading {
  margin: auto;
  margin-top: 30px;
  margin-bottom: 50px;
  font-weight: bolder;
  color: #c70039;
}
.productcontainer {
  padding: 10px;
  /* width: 500px; 
  height: 500px;  */
  display: flex;
  justify-content: center;
  align-items: center;
}

img.productIMG {
  border: red;
}
/* @media (max-width: 320px) {
  .productcontainer {
    width: 500px; 
    height: 500px; 
  }
} */

.functionImage {
  width: 80%;
  height: 310px;
  border-radius: 8px;
}

@media (max-width: 425px) {
  .functionImage {
    width: 100%;
    height: 200px;
    border-radius: 8px;
  }
}
